/* App.css */

body {
  background-color: #222; /* Dark background */
  color: white; /* Text color */
  margin: 0;
  padding: 0;
}

.top-bar {
  background-color: Black;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.login-button {
  background-color: white; /* Background */
  color: black; /* Text color */
  border: 2px solid black; /* Border */
  padding: 12px 24px; /* Padding */
  border-radius: 24px; /* Rounded corners */
  font-size: 16px; /* Font size */
  cursor: pointer; /* Cursor style */
  transition: all 0.3s ease; /* Transition */
}

.login-button:hover {
  background-color: mediumpurple; /* Background color when hovering */
  color: white; /* Text color when hovering */
}

.admin-read-guess-button{
  background-color: white; /* Background */
  color: black; /* Text color */
  border: 2px solid black; /* Border */
  padding: 12px 24px; /* Padding */
  border-radius: 24px; /* Rounded corners */
  font-size: 16px; /* Font size */
  cursor: pointer; /* Cursor style */
  transition: all 0.3s ease; /* Transition */
}

.admin-read-guess-button:hover {
  background-color: mediumpurple; /* Background color when hovering */
  color: white; /* Text color when hovering */
}

.admin-read-guess-button-container{
  display: flex;
  justify-content: center;
  margin-bottom: 20px;

}

.login-info {
  display: flex;
  margin-right: 10px;
  font-size: 20px;
  font-weight: bold;
}

.edit-button-container {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.edit-button-link {
  text-decoration: none;  /* Remove text decoration */
  display: inline-block;
}

.edit-button {
  background-color: white;
  color: black;
  border: 2px solid black;
  padding: 12px 24px;
  border-radius: 24px;
  font-size: 16px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.edit-button:hover {
  background-color: mediumpurple;
  color: white;
}



.edit-button {
  background-color: white; /* Background */
  align-items: center;
  color: black; /* Text color */
  border: 2px solid black; /* Border */
  padding: 12px 24px; /* Padding */
  border-radius: 24px; /* Rounded corners */
  font-size: 16px; /* Font size */
  cursor: pointer; /* Cursor style */
  transition: all 0.3s ease; /* Transition */
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
  margin-bottom: 50px;
}

.edit-button:hover {
  background-color: mediumpurple; /* Background color when hovering */
  color: white; /* Text color when hovering */
}

.banner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 0;
  padding-bottom: 16.67%; /* 100% / 6 */
  position: relative;
}

.banner-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.logo {
  font-size: 24px;
  width: 200px;
  height: auto;
  margin: 20px;
}

.about-us {
  display: flex;
  flex-direction: column;
  margin: 20px;
  padding: 20px;
  background-color: #222;  /* Light grey background */
  text-align: center;
}

.page-buttons a {
  color: white;
  text-decoration: none;
  margin: 0 15px;
  font-weight: bold;  /* Make text bold */
  padding-right: 15px;
  font-size: 18px/* Adjust the right padding */
}

.page-buttons a:visited {
  color: white;
}

.page-buttons {
  display: flex;
  flex-wrap: wrap; /* Allows the items to wrap to the next line */
  justify-content: space-between;
  gap: 1em; /* Provides some spacing between items */
}

.navigation-container {
  background-color: black;
  display: flex;
  justify-content: center;
  padding: 1rem;
}

@media (max-width: 600px) {
  .page-buttons {
    flex-direction: column;
    align-items: center;
  }
}

.welcome {
  display: flex;
  flex-direction: column;
  margin: 20px;
  padding: 20px;
  background-color: #222;  /* Light grey background */
  text-align: center;
}

.spotify_embed{
  align-items: center;
  margin-bottom: 100px;
}


.footer {
  background-color: black;
  color: white;
  text-align: center;
  padding: 1rem;
  position: relative;
  bottom: 0;
  width: 100%;
}

.footer i {
  margin: 0 10px;
  font-size: 24px; /* Adjust the size as needed */
}

.social-text{
  display: flex;
  flex-direction: column;
  text-align: center;
  margin-top: 100px;
}

.social-links {
  margin-top: 5px;
  display: flex;
  justify-content: center;
  padding: 1rem;
}

.social-links a {
  margin: 0 15px;
  display: flex;
  align-items: center;
}

.social-icon {
  width: 40px;  /* Adjust as needed */
  height: auto;
  margin-right: 8px;
}

.new-section {
  background-color: black;
  color: black;
  padding: 1rem;
  text-align: center;
}

.new-section a {
  color: black;
  text-decoration: none;
}

.new-section a:hover {
  text-decoration: underline;
}


  .input-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .input-container input,
  .input-container select,
  .input-container textarea {
    max-width: 300px;
  }


.submit-title{
  text-align: center;
}

.submit-button {
  display: block;
  margin: auto;
  padding: 10px 20px;
  background-color: white; /* Background */
  color: black; /* Text color */
  border: 2px solid black; /* Border */
  padding: 12px 24px; /* Padding */
  border-radius: 24px; /* Rounded corners */
  font-size: 16px; /* Font size */
  cursor: pointer; /* Cursor style */
  transition: all 0.3s ease; /* Transition */
  margin-bottom: 100px;
  /* Add other styles for your submit button here */
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus {
  /* Add styles for autofilled input fields here */
}


.successful-text{
  margin: 20px;
  padding: 20px;
  background-color: #222;  /* Light grey background */
  text-align: center;
}


.table-bordered {
  border-collapse: collapse;
  width: 100%;
}

.table-bordered th, .table-bordered td {
  border: 1px solid black;
  padding: 8px;
  text-align: center;
}

.table-bordered th {
  background-color: mediumpurple;
}

.not-found{
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 150px;
  margin-bottom: 150px;
}

.user-info{
  display: flex;
  flex-direction: column;
  align-items: center;
}

.header-listenerportal{
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.listener-portal-header{
  text-align: center;
  margin-top: 15px;
  margin-bottom: 50px;
}


.submit-login{
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 35px;
}

.admin-title{
  text-align: center;
  align-items: center;
  margin-top: 35px;
  margin-bottom: 35px;
}


.reveal-text{
  text-align: center;
  font-size: 2em;
}

.story-details-admin-read{
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}


.input-container-admin-read {
  display: flex;
  flex-direction: row; /* Ensures the items are in a row */
  justify-content: center; /* Centers items horizontally */
  align-items: center; /* Centers items vertically */
  margin-left: auto;
  margin-right: auto;
}

.input-container-admin-read input,
.input-container-admin-read select,
.input-container-admin-read textarea {
  max-width: 300px;
  margin: 0 5px; /* Adds a little space between each item */
}


.button-container-admin-read {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 50px;
}

/* Individual Buttons */
.button-admin-read {
  background-color: white; /* Background */
  color: black; /* Text color */
  border: 2px solid black; /* Border */
  padding: 12px 24px; /* Padding */
  border-radius: 24px; /* Rounded corners */
  font-size: 16px; /* Font size */
  cursor: pointer; /* Cursor style */
  transition: all 0.3s ease; /* Transition */
  margin: 0 10px; /* Adds a little space between each button */
}

.button-admin-read:hover {
  background-color: mediumpurple; /* Background color when hovering */
  color: white; /* Text color when hovering */
}

.story-details-admin-read ul {
  list-style-type: none;
  padding-left: 0;
}

/* submit */
.submit-disclaim{
  display: flex;
  flex-direction: column;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  max-width: 450px;
}